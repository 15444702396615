:root {
    --color-primary: #171739;
    --color-secondary: #6969ff;
    --color-terciary: #5353d5;

    --color-black: #212121;
    --color-white: #ffffff;

    --color-primary-rgb: 23, 23, 57;
    --color-secondary-rgb: 105, 105, 255;
    --color-terciary-rgb: 83, 83, 213;

    --color-black-rgb: 33, 33, 33;
    --color-white-rgb: 255, 255, 255;
}
