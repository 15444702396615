@font-face {
    font-family: 'Basier SemiBold';
    src: local('Basier SemiBold'),
        url(../assets/fonts/Basier/BasierCircle-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Basier';
    src: local('Basier'),
        url(../assets/fonts/Basier/BasierCircle-Regular.otf) format('opentype');
}
