/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Fonts & basic variables.
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Dimensions.
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
@keyframes loading-fade {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
.renderer-core-form-contenet-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.renderer-core-form-contenet-wrapper .renderer-core-form-content {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.renderer-core-form-contenet-wrapper .block__contentWrapper {
  padding: 50px 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  word-break: break-word;
}

.renderer-core-form-brand-logo {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  padding: 14px 16px 10px;
  box-sizing: border-box;
  background: transparent;
  pointer-events: none;
  z-index: 10000;
  opacity: 1;
}

.renderer-core-form-brand-logo img {
  max-height: 40px;
  max-width: 96px;
}

.renderer-components-default-thankyou-screen {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 26px;
  padding: 30px;
  line-height: 1.5em;
  text-align: center;
  height: 100%;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: 0.8s opacity;
}
.renderer-components-default-thankyou-screen.active {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}
.renderer-components-default-thankyou-screen .qf-thankyou-screen-block__content-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 700px;
}
@media (max-width: 600px) {
  .renderer-components-default-thankyou-screen {
    font-size: 22px;
  }
}

.renderer-core-block-attachment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}
.renderer-core-block-attachment .renderer-core-block-attachment__image {
  max-width: 100%;
}
.renderer-core-block-attachment .renderer-core-block-attachment__placeholder {
  width: 270px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(79, 176, 174, 0.2);
}
.renderer-core-block-attachment .renderer-core-block-attachment__placeholder .renderer-core-block-attachment__placeholder-icon {
  width: 40px;
  height: 40px;
  fill: #7599a5;
}

.renderer-components-block-counter {
  position: absolute;
  right: 100%;
  margin-right: 16px;
  top: 0px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
@media (max-width: 600px) {
  .renderer-components-block-counter {
    font-size: 14px;
    margin-right: 7px;
  }
}
.renderer-components-block-counter .renderer-components-block-counter__value {
  margin-right: 3px;
}
.renderer-components-block-counter .renderer-components-block-counter__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.renderer-components-block-counter .renderer-components-block-counter__content .renderer-components-block-counter__arrow-icon {
  font-size: 16px;
  width: 16px;
  stroke-width: 2;
}
@media (max-width: 600px) {
  .renderer-components-block-counter .renderer-components-block-counter__content .renderer-components-block-counter__arrow-icon {
    font-size: 14px;
    width: 14px;
  }
}

html[dir=rtl] .renderer-components-block-counter__arrow-icon {
  transform: rotate(180deg);
}

.renderer-components-block-description {
  opacity: 0.7;
  margin-top: 12px;
}

.renderer-components-field-wrapper.lg-typography-preset .renderer-components-block-description {
  margin-top: 12px;
}

.renderer-components-field-wrapper.md-typography-preset .renderer-components-block-description {
  margin-top: 8px;
}

.renderer-components-field-wrapper.sm-typography-preset .renderer-components-block-description {
  margin-top: 8px;
}

.renderer-components-block-description p {
  margin-top: 0;
  margin-bottom: 0;
}

.renderer-core-field-footer {
  height: 60px;
  margin-top: 16px;
  margin-bottom: 40px;
}
.renderer-core-field-footer.is-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -555;
}

.renderer-core-field-wrapper.lg-typography-preset.renderer-core-field-footer {
  margin-top: 18px;
}

.renderer-core-field-wrapper.md-typography-preset.renderer-core-field-footer {
  margin-top: 16px;
}

.renderer-core-field-wrapper.sm-typography-preset.renderer-core-field-footer {
  margin-top: 14px;
}

.renderer-components-block-label {
  margin-top: 0;
  margin-bottom: 0;
}

.renderer-core-field-navigation {
  display: inline-flex;
  margin-left: 8px;
}
.renderer-core-field-navigation.hidden {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.renderer-core-field-navigation > div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 2px;
  width: 37px;
  border-radius: 5px;
  cursor: pointer;
  height: 37px;
}
@media (max-width: 550px) {
  .renderer-core-field-navigation > div {
    width: 25px;
    height: 25px;
  }
  .renderer-core-field-navigation > div svg {
    width: 17px;
    height: 17px;
  }
}
.renderer-core-field-navigation > div svg {
  width: 20px;
  height: 20px;
}
.renderer-core-field-navigation .renderer-core-field-navigation__up-icon.rotate {
  transform: rotate(-90deg);
}
.renderer-core-field-navigation .renderer-core-field-navigation__down-icon.rotate {
  transform: rotate(-90deg);
}

.renderer-components-error-message-wrapper {
  display: inline-block;
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 14px;
}

.renderer-core-button {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  line-height: 1.5;
  outline: none;
  cursor: pointer;
}
.renderer-core-button svg {
  margin-left: 14px;
  width: 16px;
  height: 16px;
}

html[dir=rtl] .renderer-core-button svg {
  transform: rotate(180deg);
}
html[dir=rtl] .renderer-core-button:hover svg {
  transform: rotate(180deg) translateX(-3px);
}

.renderer-components-question-header {
  position: relative;
}

.renderer-core-field-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  transition: all linear 0.15s;
}
.renderer-core-field-action, .renderer-core-field-action * {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.renderer-core-field-action.is-visible {
  transform: translateY(0px);
}
.renderer-core-field-action.is-visible, .renderer-core-field-action.is-visible * {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.renderer-core-fields-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}
.renderer-core-fields-wrapper.active {
  opacity: 1;
  z-index: 1111;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.renderer-components-field-wrapper {
  position: absolute;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  overflow-y: auto;
  outline: none !important;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: trasnslate3d(0, 0, 0);
  transition: transform 0.6s ease 0s, opacity 0.28s ease 0s;
  -webkit-transition: transform 0.6s ease 0s, opacity 0.28s ease 0s;
  -moz-transition: transform 0.6s ease 0s, opacity 0.28s ease 0s;
  will-change: transform, z-index, visibility, opacity;
  z-index: 0;
}
.renderer-components-field-wrapper.active {
  z-index: 1;
  opacity: 1;
}
.renderer-components-field-wrapper.active .renderer-components-field-wrapper__content.is-shaking {
  animation: isShaking 0.1s linear infinite;
}
.renderer-components-field-wrapper:not(.active) {
  pointer-events: none;
}
.renderer-components-field-wrapper.is-up.is-horizontal-animation {
  transform: translate3d(-100%, 0, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
}
.renderer-components-field-wrapper.is-down.is-horizontal-animation {
  transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
}
.renderer-components-field-wrapper.is-up {
  transform: translate3d(0, -100%, 0);
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
}
.renderer-components-field-wrapper.is-down {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
}
.renderer-components-field-wrapper section {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
}
.renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper:focus {
  box-shadow: none !important;
  outline: none !important;
}
.renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper .renderer-core-block-scroller {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 70px;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
}
@media (max-width: 600px) {
  .renderer-components-field-wrapper .renderer-components-field-wrapper__content-wrapper .renderer-core-block-scroller {
    padding: 40px 40px;
  }
}

@keyframes isShaking {
  0% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  100% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
}
.renderer-core-fields-wrapper.is-moving-down .renderer-core-field-display-wrapper,
.renderer-core-fields-wrapper.is-moving-up .renderer-components-question-header {
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
}

.renderer-components-field-wrapper:not(.active) .renderer-core-block-attachment-wrapper {
  transition: none;
}

.renderer-components-field-wrapper.active:not(.is-animating) .renderer-core-block-attachment-wrapper,
.qf-welcome-screen-block__wrapper.active .renderer-core-block-attachment-wrapper,
.qf-thankyou-screen-block__wrapper.active .renderer-core-block-attachment-wrapper {
  opacity: 1;
  transform: none;
}

.renderer-core-block-split-left-layout,
.renderer-core-block-split-right-layout,
.renderer-core-block-float-left-layout,
.renderer-core-block-float-right-layout {
  display: flex !important;
}
@media (min-width: 600px) {
  .renderer-core-block-split-left-layout > div,
.renderer-core-block-split-right-layout > div,
.renderer-core-block-float-left-layout > div,
.renderer-core-block-float-right-layout > div {
    width: 50% !important;
  }
  .renderer-core-block-split-left-layout .renderer-core-block-attachment-wrapper,
.renderer-core-block-split-right-layout .renderer-core-block-attachment-wrapper,
.renderer-core-block-float-left-layout .renderer-core-block-attachment-wrapper,
.renderer-core-block-float-right-layout .renderer-core-block-attachment-wrapper {
    opacity: 0;
    transform: translate(50px);
    transition: all 400ms ease-in-out;
  }
}
@media (max-width: 599px) {
  .renderer-core-block-split-left-layout,
.renderer-core-block-split-right-layout,
.renderer-core-block-float-left-layout,
.renderer-core-block-float-right-layout {
    flex-direction: column !important;
  }
}

@media (min-width: 600px) {
  .renderer-core-block-split-left-layout .renderer-core-block-attachment-wrapper,
.renderer-core-block-float-left-layout .renderer-core-block-attachment-wrapper {
    transform: translate(-50px);
  }
}
.renderer-core-block-split-left-layout,
.renderer-core-block-float-left-layout {
  flex-direction: row-reverse;
}

.renderer-core-block-float-left-layout,
.renderer-core-block-float-right-layout {
  align-items: center;
}
.renderer-core-block-float-left-layout .renderer-core-block-attachment,
.renderer-core-block-float-left-layout .renderer-core-block-attachment img,
.renderer-core-block-float-right-layout .renderer-core-block-attachment,
.renderer-core-block-float-right-layout .renderer-core-block-attachment img {
  margin: 0 auto;
}

.renderer-core-block-split-left-layout .renderer-core-block-attachment,
.renderer-core-block-split-right-layout .renderer-core-block-attachment {
  height: 100%;
  margin-top: 0;
}
.renderer-core-block-split-left-layout .renderer-core-block-attachment img,
.renderer-core-block-split-right-layout .renderer-core-block-attachment img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
@media (max-width: 599px) {
  .renderer-core-block-split-left-layout .renderer-core-block-attachment .renderer-core-block-attachment-wrapper,
.renderer-core-block-split-right-layout .renderer-core-block-attachment .renderer-core-block-attachment-wrapper {
    height: 192px;
  }
}

.correct-incorrect-explanation-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px;
}

.correct-incorrect-explanation {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 200px;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.85);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.4s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.4s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.4s ease-in-out;
}
.correct-incorrect-explanation.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.correct-incorrect-explanation .correct-incorrect-explanation__heading {
  margin-bottom: 30px;
}

.renderer-core-html-parser p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  line-height: inherit;
}

.renderer-core-payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px;
  overflow-y: auto;
}
.renderer-core-payment-modal .renderer-core-payment-modal__content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}
.renderer-core-payment-modal .renderer-components-radio-control__options-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.renderer-core-payment-modal .renderer-components-radio-control__option {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 260px;
  word-break: break-all;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  border: 1px solid;
  cursor: pointer;
}
.renderer-core-payment-modal .renderer-components-radio-control__option input[type=radio] {
  position: absolute;
  visibility: hidden;
}
.renderer-core-payment-modal .renderer-components-radio-control__option label {
  position: relative;
  /* font-weight: 300; */
  font-size: 14px;
  margin: 4.5px 30px;
  /* height: 37px; */
  z-index: 9;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.25s linear;
}
.renderer-core-payment-modal .renderer-components-radio-control__option .check {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: 3px solid;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
.renderer-core-payment-modal .renderer-components-radio-control__option .check::before {
  content: "";
  border-radius: 100%;
  height: 7px;
  width: 7px;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}
.renderer-core-payment-modal .renderer-core-payment-modal-methods {
  text-align: center;
  margin: auto;
  max-width: 540px;
  flex-direction: column;
  display: flex;
}
.renderer-core-payment-modal .renderer-core-payment-modal-info {
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 40px;
}
.renderer-core-payment-modal .renderer-core-payment-modal-info .renderer-core-payment-order__item-row,
.renderer-core-payment-modal .renderer-core-payment-modal-info .renderer-core-payment-modal-info_footer,
.renderer-core-payment-modal .renderer-core-payment-modal-info .renderer-core-payment-modal-info_discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.renderer-core-payment-coupon {
  padding: 15px;
  margin-bottom: 20px;
}
.renderer-core-payment-coupon .renderer-core-payment-coupon__label {
  margin-right: 15px;
  cursor: pointer;
  text-decoration: underline;
}
.renderer-core-payment-coupon .render-core-payment-coupon-form {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.renderer-core-payment-coupon .render-core-payment-coupon-form .renderer-core-payment-coupon__apply {
  margin-left: 15px;
}

.renderer-core-progress-bar {
  padding: 5px 8px;
  width: 220px;
  overflow: hidden;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .renderer-core-progress-bar {
    background-color: transparent !important;
    width: 0px;
  }
  .renderer-core-progress-bar .renderer-core-progress-bar__label {
    display: none !important;
  }
}
.renderer-core-progress-bar .renderer-core-progress-bar__track {
  position: relative;
  height: 5px;
  width: 100%;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .renderer-core-progress-bar .renderer-core-progress-bar__track {
    position: fixed;
    top: 0;
    margin-top: 0;
    right: 0;
    left: 0;
  }
}
.renderer-core-progress-bar .renderer-core-progress-bar__fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -o-transition: width 0.3s;
}

.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 2222;
  bottom: 0;
  right: 0;
  left: 0;
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer .renderer-components-form-footer__inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 10px 20px;
}
@media (max-width: 600px) {
  .renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer .renderer-components-form-footer__inner {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer.hidden {
  display: none;
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer [class^=renderer-core-powered-by-branding] {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  margin-left: 6px;
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer [class^=renderer-core-powered-by-branding] a {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer .renderer-core-correct-incorrect-result {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}
.renderer-core-form-flow__wrapper .renderer-core-form-flow .renderer-components-form-footer .renderer-core-correct-incorrect-result.active {
  transform: translateY(0);
}

.renderer-components-field-content {
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}
.renderer-components-field-content.is-shaking {
  animation: isShaking 0.1s linear infinite;
}

.renderer-core-submit-btn__loader {
  margin-left: 10px;
}